import { toast } from "react-hot-toast";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { replaceAll, uppercaseFirstLetter } from "./helper";
import { getAuthToken, setToken } from "./auth";
import CustomToast from "components/toast";

export const request = axios.create({
  baseURL: process.env.NODE_ENV === "production" && process.env.REACT_APP_API_URL,
  timeout: 100000000,
});

export const requestNoToast = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

requestNoToast.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }

  const token = getAuthToken();
  if (token) {
    config.headers["Accept-Language"] = `id-ID`;
    config.headers["Authorization"] = `Bearer ${token.access}`;
  }
  return config;
});

requestNoToast.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

request.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }

  const token = getAuthToken();
  if (token?.access) {
    config.headers["Authorization"] = `Bearer ${token.access}`;
  }

  return config;
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const responseRequest = err.response.data;

    const errorStatus = [400, 401, 403];

    if (errorStatus.includes(err.response.status)) {
      const arrayErrorMessage = responseRequest?.data?.msg;

      if (
        arrayErrorMessage?.[0] !== "Given token not valid for any token type"
      ) {
        let errorStatusValidation = [];

        arrayErrorMessage.map((elem) =>
          typeof elem === "string"
            ? errorStatusValidation.push(elem)
            : Object.entries(elem).map(([key, value]) =>
                errorStatusValidation.push({
                  [key]: value.join(),
                })
              )
        );

        errorStatusValidation.forEach((errorMessage) => {
          if (typeof errorMessage === "string") {
            toast.custom((t) => (
              <CustomToast
                scheme="error"
                title="Error"
                description={errorMessage}
                onClose={() => toast.dismiss(t.id)}
              />
            ));
          } else {
            Object.entries(errorMessage).map(([key, value]) =>
              toast.custom((t) => (
                <CustomToast
                  scheme="error"
                  title="Error"
                  onClose={() => toast.dismiss(t.id)}
                  description={`${uppercaseFirstLetter(
                    replaceAll(key, "_", " ")
                  )}: ${value}`}
                />
              ))
            );
          }
        });
      }
    } else {
      toast.custom((t) => (
        <CustomToast
          scheme="error"
          title="Error"
          onClose={() => toast.dismiss(t.id)}
          description="Maaf Server Kami Sedang bermasalah, Silakan coba lagi nanti !"
        />
      ));

      return Promise.reject(err);
    }

    // retrurn err;
    return Promise.reject(err);
  }
);

const refreshAuthLogic = (failedRequest) =>
  requestNoToast
    .post("/api/v2/auth/refresh/", {
      refresh: getAuthToken().refresh,
    })
    .then((tokenResponse) => {
      const newToken = tokenResponse.data;
      setToken(newToken);
      failedRequest.response.config.headers[
        "Authorization"
      ] = `Bearer ${newToken}`;

      return Promise.resolve();
    })
    .catch(() => {
      setToken(null);
      window.location.replace("/masuk");
    });

createAuthRefreshInterceptor(request, refreshAuthLogic);
