import {
  REMOVE_USER_DATA,
  SET_APP_FEATURES,
  SET_USER_DATA,
  SET_VILLAGE_DATA,
} from "./type";

const authState = {
  user: null,
  features: [],
  village: null,
};

const authReducers = (state = authState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, user: action.payload };
    case REMOVE_USER_DATA:
      return { ...state, user: null, village: null };
    case SET_VILLAGE_DATA:
      return { ...state, village: action.payload };
    case SET_APP_FEATURES:
      return { ...state, features: action.payload };
    default:
      return state;
  }
};

export default authReducers;
