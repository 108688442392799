import { encode } from "js-base64";
import CryptoJS from "crypto-js";
import formatDistance from "date-fns/formatDistance";
import isDateValid from "date-fns/isValid";
import idLocale from "date-fns/locale/id";
import { USER_LEVEL, NOTIF_KEY } from "./constant";
import { request } from "./request";
import { getAuthToken } from "./auth";

const replaceAll = (text, replaceFrom, replaceAfter) => {
  const regex = new RegExp(replaceFrom, "g");
  const results = text?.replace(regex, replaceAfter);

  return results;
};

const uppercaseFirstLetter = (words) => {
  if (words) {
    let wordList = words?.split(" ");

    for (let i = 0; i < wordList.length; i++) {
      if (wordList[i].length < 3) {
        wordList[i] = wordList[i].toUpperCase();
      } else {
        wordList[i] = wordList[i][0].toUpperCase() + wordList[i].substr(1);
      }
    }

    return wordList.join(" ")?.replace(/_/g, " ")?.replace(/-/g, " ");
  }

  return "-";
};

const filterRoutes = (routes, hierarki, userLevel) => {
  if (userLevel === USER_LEVEL.VILLAGE) {
    if (routes && hierarki !== undefined) {
      if (hierarki !== null) {
        routes.map((route) => {
          Object.entries(hierarki).forEach(([key, value]) => {
            if (key === route.type) {
              route.show = value;
            }

            if (
              key === "jenis_subdesa" &&
              route.type === "subdesa" &&
              route.isRead
            ) {
              route.name = uppercaseFirstLetter(value);
            }

            if (
              key === "jenis_subsubdesa" &&
              route.type === "subsubdesa" &&
              route.isRead
            ) {
              route.name = uppercaseFirstLetter(value);
            }
          });
        });

        /* check if routes children */
        if (routes[0]?.show) {
          return routes?.filter((el) => el.show);
        }
        return routes;
      }
    }
  }

  return routes;
};

const getNumberList = (index, page = 1, perPage = 5) => {
  return index + 1 + (parseInt(page ?? 1) - 1) * parseInt(perPage ?? 5);
};

const nullChecker = (cell) => (!cell ? "-" : cell);

const isLetter = (str) => /\p{L}/u.test(str);

const requestAll = (urls) => urls.map((url) => request.get(url));

const base64Formatter = (str) => `data:image/png;base64,${str}`;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result.replace(/^data:image.+;base64,/, ""));
    reader.onerror = (error) => reject(error);
  });

const sumObject = (list, key) => {
  return list?.reduce((a, b) => a + (b[key] || 0), 0);
};

const priceToInt = (price) => {
  const priceValue = parseInt(price.replaceAll("Rp", "").replaceAll(",", ""));

  return priceValue;
};

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const manipulateData = (data) => {
  const $obj = {
    // btw ini yang tadinya symmetric encryption
    // sekarang jadi random hasher
    encrypt: () =>
      `${CryptoJS.AES.encrypt(String(data), encode(data))
        .toString()
        .replaceAll("/", "+")}${data}ajd3+2812=12`,

    decrypt: () => {
      const getDataFromCrypt = data.substring(0, data.indexOf("ajd3+2812=12"));

      return getDataFromCrypt;
    },
    // decryptString: () => {
    //   const hash = $obj.decrypt().replaceAll("+", "/");
    //   return CryptoJS.AES.decrypt(String(hash), decode(hash)).toString(CryptoJS.enc.Utf8);
    // }
  };
  return $obj;
};

const getFeedSuggestions = async (queryText) => {
  let items = await request.get(
    `/api/v2/layanan/config-layanan-surat/default-available-field/`
  );

  const isItemMatching = (item) => {
    // Make the search case-insensitive.
    const searchString = queryText.toLowerCase();

    // Include an item in the search results if the name or username includes the current user input.
    return item.toLowerCase().includes(searchString);
  };

  return new Promise((resolve) => {
    setTimeout(() => {
      const itemsMapper = [
        ...items.data.data.map((el) => `@${el.nama}`),
        "@tambah.atribut",
      ];

      const itemsToDisplay = itemsMapper
        // Filter out the full list of all items to only those matching the query text.
        .filter(isItemMatching)
        // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
        .slice(0, 10);

      resolve(itemsToDisplay);
    }, 100);
  });
};

const filterValuesObject = (values, keyArray) => {
  const filterValues = Object.entries(values).filter(
    ([key]) => !keyArray.includes(key)
  );

  return Object.fromEntries(filterValues);
};

const requestDownload = (url, filename = "Download") => {
  return request.get(url, { responseType: "arraybuffer" }).then((response) => {
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type, encoding: "UTF-8" });
    let name = filename;
    const disposition = response.headers["content-disposition"];
    if (disposition && disposition.indexOf("inline") !== -1) {
      const filenameregex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameregex.exec(disposition);
      if (matches !== null && matches[1]) {
        name = matches[1].replace(/['"]/g, "");
      }
    }

    const URL = window.URL || window.webkitURL;
    const donwloadurl = URL.createObjectURL(blob);

    const iOS =
      window.navigator.platform &&
      /iPad|iPhone|iPod/.test(window.navigator.platform);
    if (iOS) {
      const reader = new FileReader();
      reader.onload = function (e) {
        let newWindow = window.open(reader.result);
        newWindow.onload = function () {
          newWindow.document
            .getElementsByTagName("html")[0]
            .appendChild(document.createElement("head"))
            .appendChild(document.createElement("title"))
            .appendChild(document.createTextNode("name"));
        };

        setTimeout(() => {
          newWindow.document.title = name;
        }, 100);
      };
      reader.readAsDataURL(blob);
    } else {
      const link = document.createElement("a");
      link.href = donwloadurl;
      link.target = "_blank";
      link.click();
      setTimeout(() => {
        link.remove();
      }, 1500);
    }

    return Promise.resolve(true);
  });
};

const isLatitude = (lat) => {
  return isFinite(lat) && Math.abs(lat) <= 90;
};

const isLongitude = (lng) => {
  return isFinite(lng) && Math.abs(lng) <= 180;
};

const isFile = (input) => {
  if ("File" in window && input instanceof File) return true;
  else return false;
};

const filterFileInImagesUploader = (values, imageName) => {
  const images_binary = values?.[imageName]?.filter((el) => isFile(el));
  const images_url = values?.[imageName]?.filter(
    (el) => typeof el === "string"
  );

  return { images_binary, images_url };
};

const filterMenuByExistingHierarki = (appMenus, hierarki) => {
  const isFilterRoutesExistingHierarki = appMenus.map((route) => {
    if (route.name?.toLowerCase() === "profil") {
      return {
        ...route,
        children: route.children.map((child) => {
          if (child.name?.toLowerCase() === "hierarki desa") {
            return {
              ...child,
              children: child.children
                .map((childChild) => {
                  if (
                    childChild?.key?.toLowerCase() === "subdesa" &&
                    hierarki?.subdesa
                  ) {
                    if (childChild.name) {
                      return {
                        ...childChild,
                        name: hierarki?.jenis_subdesa,
                      };
                    }

                    return childChild;
                  }

                  if (
                    childChild?.key?.toLowerCase() === "subsubdesa" &&
                    hierarki?.subsubdesa
                  ) {
                    if (childChild.name) {
                      return {
                        ...childChild,
                        name: hierarki?.jenis_subsubdesa,
                      };
                    }

                    return childChild;
                  }

                  if (childChild?.key?.toLowerCase() === "rw" && hierarki?.rw) {
                    return childChild;
                  }

                  if (childChild?.key?.toLowerCase() === "rt" && hierarki?.rt) {
                    return childChild;
                  }

                  return null;
                })
                .filter((el) => el),
            };
          }

          return child;
        }),
      };
    }

    return route;
  });
  return isFilterRoutesExistingHierarki;
};

const filterSelectValues = (values) => {
  const entriesValues = Object.entries(values).map(([key, value]) => {
    if (typeof value === "object") {
      return [key, value?.value];
    }

    return [key, value];
  });

  const results = Object.fromEntries(entriesValues);

  return results;
};

const uniqueArray = (array) => {
  const results = array?.filter((thing, index) => {
    const _thing = JSON.stringify(thing);
    return (
      index ===
      array.findIndex((obj) => {
        return JSON.stringify(obj) === _thing;
      })
    );
  });

  return results;
};

const convertFormatDistance = (date) => {
  let pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
  if (!date || !date.match(pattern)) {
    return "-";
  }

  const defaultDate = date.replace(pattern, "$2/$3/$1");

  const formatDate = formatDistance(new Date(), new Date(defaultDate), {
    locale: idLocale,
    addSuffix: true,
    includeSeconds: true,
  });

  return formatDate;
};

const validateFormatDistance = (date /* @params string ISO8601 */) => {
  const d = new Date(date);
  if (isDateValid(d)) {
    return formatDistance(new Date(), d, {
      locale: idLocale,
      addSuffix: true,
      includeSeconds: true,
    });
  } else {
    return "-";
  }
}

const notificationAlert = (title, options) => {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification(title, options);
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        new Notification(title, options);
      }
    });
  }
};

const getSocketNotificationURL = () => {
  const authToken = getAuthToken();

  const socketURL = `wss://${process.env.REACT_APP_API_URL.replace(
    "https://",
    ""
  )}/ws/notifications?token=${authToken.access}`;

  return socketURL;
};

const getNotifInformation = (isNotif) => {
  let icon;
  let style;
  let href;

  switch (isNotif.key) {
    case NOTIF_KEY.LAPOR:
      icon = `bi bi-exclamation-diamond`;
      style = `info`;
      href = `/website/lapor/?page=1&per_page=5&q=&active=${isNotif.obj_id}`;
      break;
    case NOTIF_KEY.LAYANAN:
      icon = `bi bi-person-bounding-box`;
      style = `warning`;
      href = `/layanan/${isNotif.jenis_surat}/print/${isNotif.obj_id}`;
      break;
    case NOTIF_KEY.POTENSI:
      icon = `bi bi-star`;
      style = `success`;
      href = `/website/potensi/detail/${isNotif.obj_id}`;
      break;
    default:
      icon = "";
      style = "";
      href = "";
  }

  return {
    icon,
    style,
    href,
  };
};

const getAppFeaturesFromENV = () => {
  const appFeatures = process.env.REACT_APP_FEATURES;
  const sliceAppFeatures = appFeatures.split(",");

  return sliceAppFeatures;
};

const filterRoutesByFeaturesAndRole = (
  routes,
  features,
  userLevel,
  userAccess
) => {
  const readAccess = userAccess?.read;

  const isFilterRoutesByFeatures = routes
    .filter((route) => {
      if (features?.includes(route.access) || route.access === "all") {
        return route;
      } else {
        return undefined;
      }
    })
    .filter((route) => typeof route !== "undefined");

  const filterRoutes = isFilterRoutesByFeatures
    // filter routes from level
    .filter((route) => route.level?.includes(userLevel))
    .filter((route) => {
      if (readAccess?.includes(route.access) || route.access === "all") {
        return route;
      } else {
        return undefined;
      }
    })
    .filter((route) => typeof route !== "undefined");

  return filterRoutes;
};

export {
  replaceAll,
  uppercaseFirstLetter,
  filterRoutes,
  getNumberList,
  nullChecker,
  isLetter,
  requestAll,
  base64Formatter,
  toBase64,
  sumObject,
  priceToInt,
  formatBytes,
  manipulateData,
  getFeedSuggestions,
  filterValuesObject,
  requestDownload,
  isLatitude,
  isLongitude,
  isFile,
  filterFileInImagesUploader,
  filterMenuByExistingHierarki,
  filterSelectValues,
  uniqueArray,
  convertFormatDistance,
  validateFormatDistance,
  notificationAlert,
  getSocketNotificationURL,
  getNotifInformation,
  getAppFeaturesFromENV,
  filterRoutesByFeaturesAndRole,
};
