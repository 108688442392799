import { Cookies } from "react-cookie";

const isUserAuthenticated = () => {
  const token = getAuthToken();

  if (!token) {
    setToken(null);

    return false;
  }

  return true;
};

const getAuthToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");

  return token;
};

const getIsUserKeepLogin = () => {
  const cookies = new Cookies();
  const refresh = cookies.get("refresh");

  if (refresh === "true") {
    return true;
  }

  return false;
};

const setToken = (token) => {
  let cookies = new Cookies();
  if (token) cookies.set("token", JSON.stringify(token), { path: "/" });
  else cookies.remove("token", { path: "/" });
};

const setIsRefresh = (isRefresh) => {
  let cookies = new Cookies();
  if (isRefresh) cookies.set("refresh", isRefresh, { path: "/" });
  else cookies.remove("token", { path: "/" });
};

export {
  isUserAuthenticated,
  getAuthToken,
  setToken,
  getIsUserKeepLogin,
  setIsRefresh,
};
