import { Toast } from "react-bootstrap";
import PropTypes from "prop-types";
import { TOAST_TYPE } from "utils/constant";

const CustomToast = ({ onClose, title, description, scheme }) => {
  const iconClass =
    scheme === TOAST_TYPE.SUCCESS
      ? "bi bi-check-circle-fill text-success"
      : scheme === TOAST_TYPE.INFO
      ? "bi bi-info-circle-fill text-info"
      : scheme === TOAST_TYPE.WARNING
      ? "bi bi-exclamation-circle-fill text-warning"
      : scheme === TOAST_TYPE.ERROR
      ? "bi bi-x-circle-fill text-danger"
      : "";

  return (
    <Toast show animation onClose={onClose}>
      <Toast.Header>
        <i className={`${iconClass} mr-1`} />
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{description}</Toast.Body>
    </Toast>
  );
};

CustomToast.propTypes = {
  onClose: PropTypes.func,
  scheme: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  position: PropTypes.string,
};

export default CustomToast;
