import { Suspense } from "react";
import L from "leaflet";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./routes";
import LoadingGlobal from "components/loading-global";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const App = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingGlobal />}>
        <Routers />
        <Toaster />
      </Suspense>
    </Router>
  );
};

export default App;
