import {
  SET_TABLE,
  SET_MENU,
  SW_INIT,
  SW_UPDATE,
  SET_TRIGGER_TABLE,
  SET_LOADING_APP,
  SET_HIERARKI_MENU,
  SET_NOTIFICATIONS,
} from "./type";

let appState = {
  menu: [],
  table: [],
  loading: null,
  notifications: [],
  triggerTable: false,
  serviceWorkerUpdated: false,
  serviceWorkerInitialized: false,
  serviceWorkerRegistration: null,
};

const appReducers = (state = appState, action) => {
  switch (action.type) {
    case SET_MENU:
      return { ...state, menu: action.payload };
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    case SET_TRIGGER_TABLE:
      return { ...state, triggerTable: action.payload };
    case SET_TABLE:
      return { ...state, table: action.payload };
    case SET_LOADING_APP:
      return { ...state, loading: action.payload };
    case SET_HIERARKI_MENU:
      const newMenu = state?.menu?.map((elem) => {
        if (elem?.name?.toLowerCase() === "profil") {
          return {
            ...elem,
            children: [...elem.children],
          };
        }

        return elem;
      });

      return { ...state, menu: newMenu };
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };

    default:
      return state;
  }
};

export default appReducers;
