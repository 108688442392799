import {
  REMOVE_USER_DATA,
  SET_APP_FEATURES,
  SET_USER_DATA,
  SET_VILLAGE_DATA,
} from "./type";

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: userData,
});

export const removeUserData = () => ({
  type: REMOVE_USER_DATA,
});

export const setVillageData = (villageData) => ({
  type: SET_VILLAGE_DATA,
  payload: villageData,
});

export const setAppFeatures = (appFeatures) => ({
  type: SET_APP_FEATURES,
  payload: appFeatures,
});
