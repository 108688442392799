import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import useSWR from "swr";
import { routes } from "./routes";
import { isUserAuthenticated } from "utils/auth";
import {
  setAppFeatures,
  setMenu,
  setUserData,
  setVillageData,
} from "redux/actions";
import { USER_LEVEL } from "utils/constant";
import LoadingGlobal from "components/loading-global";
import {
  filterMenuByExistingHierarki,
  filterRoutesByFeaturesAndRole,
} from "utils/helper";

const Routes = () => {
  const dispatch = useDispatch();
  const isLoggin = isUserAuthenticated();

  const { user, features } = useSelector((state) => state.auth);

  const userAccess = user?.hakakses;
  const userLevel = user?.level || USER_LEVEL.SUPERADMIN;

  const { data: userDetail, isLoading: userLoading } = useSWR(
    isLoggin ? "/api/v2/users/users/me" : null
  );
  const { data: appFeatures, isLoading: featuresLoading } = useSWR(
    isLoggin
      ? userLevel === USER_LEVEL.VILLAGE
        ? "/api/v2/role/desa-available-features/"
        : "/api/v2/role/kab-available-features/"
      : null
  );

  const { data: villageDetail, isLoading: villageLoading } = useSWR(
    userLevel === USER_LEVEL.VILLAGE ? "/api/v2/kewilayahan/desa/me" : null
  );

  const [routesLoading, setRoutesLoading] = useState(true);

  const filterRoutes = filterRoutesByFeaturesAndRole(
    routes,
    features,
    userLevel,
    userAccess
  );

  // check hierarki

  const isFilterRoutes = filterMenuByExistingHierarki(
    filterRoutes,
    villageDetail?.data?.hierarki
  );

  const filterRoutesIsNoAuth = routes.filter(
    (route) => route?.level?.[0] === USER_LEVEL.ALL
  );


  const isRoutes = isLoggin ? isFilterRoutes : filterRoutesIsNoAuth;
  const routing = useRoutes(isRoutes);

  useEffect(() => {
    if (appFeatures) {
      const appFeaturesMapper = appFeatures?.data?.map(
        (feature) => feature.name
      );

      dispatch(setAppFeatures(appFeaturesMapper));
    }
  }, [appFeatures]);

  useEffect(() => {
    if (userDetail) {
      dispatch(setUserData(userDetail));
    }
  }, [userDetail]);

  useEffect(() => {
    if (villageDetail) {
      dispatch(setVillageData(villageDetail?.data));
    }
  }, [villageDetail]);

  useEffect(() => {
    dispatch(setMenu(isFilterRoutes));
  }, [isFilterRoutes]);

  useEffect(() => {
    if (!userLoading && !villageLoading && !featuresLoading) {
      setRoutesLoading(false);
    }
  }, [userLoading, villageLoading, featuresLoading]);

  if (routesLoading) {
    return <LoadingGlobal />;
  }

  return routing;
};

export default Routes;
