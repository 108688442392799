const INPUT_TYPE = {
  INPUT: "input",
  TEXT: "text",
  TEXTAREA: "textarea",
  SELECT: "select",
  PASSWORD: "password",
  SWITCH: "switch",
  PRICE: "price",
  TIME: "time",
  SELECT_SYNC: "select_sync",
  SELECT_CREATABLE: "select_creatable",
  ASYNC_SELECT_CREATABLE: "async_select_creatable",
  UPLOAD_IMAGE: "upload_image",
  TEXT_EDITOR: "text_editor",
  RADIO: "radio",
  COLOR: "color",
  YEAR: "year",
  DATE: "date",
  PHONE: "phone",
  MASK: "mask",
  NIK: "nik",
  NO_KK: "no_kk",
  KODEPOS: "kodepos",
  AVATAR: "avatar",
  FILE_UPLOADER: "file_uploader",
  IMAGES_UPLOADER: "images_uploader",
  PINNING_MAP: "pinning_map",
  PREFIX_SUFFIX: "prefix_suffix",
  LISTS: "lists",
  NATIVE_SELECT: "native_select",
};

const REACT_SELECT_CUSTOM_STYLES = {
  menu: (styles) => ({
    ...styles,
    zIndex: 9999999,
    borderRadius: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#8898a9",
    marginLeft: ".8rem",
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: ".75rem",
    padding: ".35rem 0 .35rem 0",
    boxShadow: "none",
    height: "29px",
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    zIndex: 9999999,
    backgroundColor: isSelected ? "#4DBFA6" : isFocused ? "#4DBFA6" : undefined,
    color: isFocused ? "#fff" : isSelected ? "#fff" : undefined,
  }),
  multiValue: (styles) => ({
    ...styles,
    width: "145px",
    backgroundColor: "#CDA2BE",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    boxShadow: "none",
  }),
};

const TOAST_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  ERROR: "error",
  WARNING: "warning",
};

const DESPIN_MASTER_DATA = {
  JENIS_KEPINDAHAN: {
    KEPALA_KELUARGA: 1,
    KEPALA_KELUARGA_DAN_SEMUA_ANGGOTA: 2,
    KEPALA_KELUARGA_DAN_BEBERAPA_ANGGOTA: 3,
    ANGGOTA_KELUARGA: 4,
  },
  JENIS_KELAMIN: [
    {
      label: "Laki - Laki",
      value: "laki - laki",
    },
    { label: "Perempuan", value: "perempuan" },
  ],
  KELAINAN_FISIK: {
    ADA: "ada",
    TIDAK_ADA: "tidak ada",
  },
  TIPE_ORTU: {
    WARGA: "warga",
    NON_WARGA: "non_warga",
    TIDAK_DIKETAHUI: "tidak_diketahui",
    LISTS: [
      {
        label: "WARGA",
        value: "warga",
      },
      {
        label: "NON WARGA",
        value: "non_warga",
      },
      {
        label: "TIDAK DIKETAHUI",
        value: "tidak_diketahui",
      },
    ],
  },
};

const MASK_INPUT = {
  AKTA: [/\d/, /\d/, /\d/, /\d/, "/", /^[A-Z]*$/, "/", /\d/, /\d/, /\d/, /\d/],
  AKTA_KAWIN: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /^[A-Z]*$/,
    /^[A-Z]*$/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  AKTA_CERAI: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "/",
    /^[A-Z]*$/,
    /^[A-Z]*$/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "/",
    /^[A-Z]*$/,
    /^[A-Z]*$/,
    ".",
    /^[A-Z]*$/,
    /^[a-z]*$/,
    /^[a-z]*$/,
    /^[a-z]*$/,
    ".",
  ],
};

const USER_LEVEL = {
  VILLAGE: "desa",
  CITY: "kabupaten",
  ALL: "all",
  SUPERADMIN: "superadmin",
};

const VILLAGE_STATUS = {
  LISTS: [
    {
      label: "DESA",
      value: "desa",
    },
    {
      label: "KELURAHAN",
      value: "kelurahan",
    },
  ],
  VILLAGE: "desa",
  KELURAHAN: "kelurahan",
};

const GROUP_NAME = [
  {
    label: "Admin Desa",
    value: "admindesa",
  },
];

const TIPE_ATRIBUT = {
  INT: "int",
  FLOAT: "float",
  DATE: "date",
  STR: "str",
  LIST: "list",
  IS_DATA: [
    {
      label: "INT",
      value: "int",
    },
    {
      label: "FLOAT",
      value: "float",
    },
    {
      label: "DATE",
      value: "date",
    },
    {
      label: "STR",
      value: "str",
    },
    {
      label: "LIST",
      value: "list",
    },
  ],
};

const NOTIF_KEY = {
  LAPOR: "lapor",
  LAYANAN: "layanan",
  POTENSI: "potensi",
};

export {
  INPUT_TYPE,
  REACT_SELECT_CUSTOM_STYLES,
  TOAST_TYPE,
  DESPIN_MASTER_DATA,
  MASK_INPUT,
  USER_LEVEL,
  VILLAGE_STATUS,
  GROUP_NAME,
  TIPE_ATRIBUT,
  NOTIF_KEY,
};
