import { useState } from "react";
import {
  Modal,
  OverlayTrigger,
  Tooltip,
  Toast,
  ProgressBar,
} from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import PropTypes from "prop-types";

const LoadingGlobal = ({
  isLoadingBackground,
  countBackground,
  toastTitle,
  toastTime,
}) => {
  const [isPinnedLoader, setIsPinnedLoader] = useState(false);

  return (
    <>
      <Modal
        centered
        show={!isPinnedLoader}
        size="sm"
        className="despin-loading"
      >
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <Oval
            height={50}
            width={50}
            color="#59D5C5"
            wrapperStyle={{}}
            wrapperClass=""
            ariaLabel="oval-loading"
            secondaryColor="#59D5C5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
          {isLoadingBackground && (
            <>
              <p
                className="mb-0"
                style={{ position: "absolute", left: "50px", color: "#59D5C5" }}
              >
                {countBackground}
              </p>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>Sematkan</Tooltip>}
              >
                <button
                  className="btn btn-neutral btn-pinned"
                  onClick={() => setIsPinnedLoader(true)}
                >
                  <i className="bi bi-pin-angle" />
                </button>
              </OverlayTrigger>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Toast
        placement="top-end"
        show={isPinnedLoader}
        className="despin-progress-toast"
      >
        <Toast.Header className="bg-info text-white">
          <i className="bi bi-cloud-arrow-up" />
          &nbsp;
          <strong className="me-auto">{toastTitle}</strong>
          <small>{toastTime}</small>
        </Toast.Header>
        <Toast.Body>
          <ProgressBar animated variant="info" label={`${45}%`} now={45} />
        </Toast.Body>
      </Toast>
    </>
  );
};

LoadingGlobal.propTypes = {
  isLoadingBackground: PropTypes.bool,
  countBackground: PropTypes.string,
  toastTitle: PropTypes.string,
  toastTime: PropTypes.string,
};

export default LoadingGlobal;
