import {
  SET_MENU,
  SET_TABLE,
  SET_TRIGGER_TABLE,
  SET_LOADING_APP,
  SET_HIERARKI_MENU,
  SET_NOTIFICATIONS,
} from "./type";

export const setMenu = (menu) => ({
  type: SET_MENU,
  payload: menu,
});

export const setTriggerTable = (trigger) => ({
  type: SET_TRIGGER_TABLE,
  payload: trigger,
});

export const setTable = (tableData) => ({
  type: SET_TABLE,
  payload: tableData,
});

export const setLoadingApp = (loadingApp) => ({
  type: SET_LOADING_APP,
  payload: loadingApp,
});

export const setHierarkiMenu = (hierarkiMenu) => ({
  type: SET_HIERARKI_MENU,
  payload: hierarkiMenu,
});

export const setNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});
