import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { USER_LEVEL } from "utils/constant";

/**
 * PRIVATE ROUTE
 */

const PrivateRoute = lazy(() => import("./private"));

/**
 * AUTH
 */

const LoginPages = lazy(() => import("pages/auth/login"));
const LogoutPages = lazy(() => import("pages/auth/logout"));

/**
 * SETTING
 */
const ChangePasswordPages = lazy(() =>
  import("pages/settings/change-password")
);

/**
 * SUPERADMIN
 */

const ListsPetaDasarPages = lazy(() =>
  import("pages/superadmin/peta-dasar/lists")
);

/** VILLAGE ROUTES */

/**
 * DASHBOARD
 */

const DashboardPages = lazy(() => import("pages/village/dashboard"));

/**
 * KEPENDUDUKAN
 */

const ListPenduduk = lazy(() =>
  import("pages/village/kependudukan/penduduk/lists")
);

const CreatePenduduk = lazy(() =>
  import("pages/village/kependudukan/penduduk/create")
);

const EditPenduduk = lazy(() =>
  import("pages/village/kependudukan/penduduk/edit")
);

const DetailPenduduk = lazy(() =>
  import("pages/village/kependudukan/penduduk/detail")
);

const ListKeluarga = lazy(() =>
  import("pages/village/kependudukan/keluarga/lists")
);
const CreateKeluarga = lazy(() =>
  import("pages/village/kependudukan/keluarga/create")
);

const EditKeluarga = lazy(() =>
  import("pages/village/kependudukan/keluarga/edit")
);

const DetailKeluarga = lazy(() =>
  import("pages/village/kependudukan/keluarga/detail")
);

/**
 *
 * PERISTIWA
 *
 */

// KELAHIRAN

const ListsKelahiranPages = lazy(() =>
  import("pages/village/peristiwa/kelahiran/lists")
);

const CreateKelahiranPages = lazy(() =>
  import("pages/village/peristiwa/kelahiran/create")
);

const UpdateKelahiranPages = lazy(() =>
  import("pages/village/peristiwa/kelahiran/update")
);

const DetailKelahiranPages = lazy(() =>
  import("pages/village/peristiwa/kelahiran/detail")
);

const MoveToPendudukKelahiranPages = lazy(() =>
  import("pages/village/peristiwa/kelahiran/moveto-penduduk")
);

// KEMATIAN

const ListsKematianPages = lazy(() =>
  import("pages/village/peristiwa/kematian/lists")
);

const CreateKematianPages = lazy(() =>
  import("pages/village/peristiwa/kematian/create")
);

const DetailKematianPages = lazy(() =>
  import("pages/village/peristiwa/kematian/detail")
);

// LAHIR MATI

const ListsLahirMatiPages = lazy(() =>
  import("pages/village/peristiwa/lahirmati/lists")
);

const CreateLahirMatiPages = lazy(() =>
  import("pages/village/peristiwa/lahirmati/create")
);

const DetailLahirMatiPages = lazy(() =>
  import("pages/village/peristiwa/lahirmati/detail")
);

// PECAH KK

const ListsPecahKKPages = lazy(() =>
  import("pages/village/peristiwa/pecahkk/lists")
);

const CreatePecahKKPages = lazy(() =>
  import("pages/village/peristiwa/pecahkk/create")
);

const DetailPecahKKPages = lazy(() =>
  import("pages/village/peristiwa/pecahkk/detail")
);

// PINDAH KELUAR

const ListsPindahKeluarPages = lazy(() =>
  import("pages/village/peristiwa/pindahkeluar/lists")
);

const CreatePindahKeluarPages = lazy(() =>
  import("pages/village/peristiwa/pindahkeluar/create")
);

const DetailPindahKeluarPages = lazy(() =>
  import("pages/village/peristiwa/pindahkeluar/detail")
);

// DATANG MASUK

const ListsDatangMasukPages = lazy(() =>
  import("pages/village/peristiwa/datangmasuk/lists")
);

const CreateDatangMasukPages = lazy(() =>
  import("pages/village/peristiwa/datangmasuk/create")
);

const DetailDatangMasukPages = lazy(() =>
  import("pages/village/peristiwa/datangmasuk/detail")
);

/**
 * LAYANAN
 *
 */

const ListLayananPages = lazy(() => import("pages/village/layanan/lists"));
const ListLayananSlugPages = lazy(() =>
  import("pages/village/layanan/slug/lists")
);
const CreateLayananSlugPages = lazy(() =>
  import("pages/village/layanan/slug/create")
);
const PrintLetterLayananPages = lazy(() =>
  import("pages/village/layanan/slug/print")
);

/**
 *
 * WEBSITE
 *
 */

// BERITA

const ListsBeritaPages = lazy(() =>
  import("pages/village/website/berita/lists")
);
const CreateBeritaPages = lazy(() =>
  import("pages/village/website/berita/create")
);
const UpdateBeritaPages = lazy(() =>
  import("pages/village/website/berita/update")
);
const ListsKategoriBeritaPage = lazy(() =>
  import("pages/village/website/kategori_berita/lists")
);
const EditKategoriBeritaPage = lazy(() =>
  import("pages/village/website/kategori_berita/edit")
);
const CreateKategoriBeritaPage = lazy(() =>
  import("pages/village/website/kategori_berita/create")
);

// HERO

const ListsHeroPages = lazy(() => import("pages/village/website/hero/lists"));
const CreateHeroPages = lazy(() => import("pages/village/website/hero/create"));
const UpdateHeroPages = lazy(() => import("pages/village/website/hero/update"));

// INFORMASI

const ListsInformasiPages = lazy(() =>
  import("pages/village/website/informasi/lists")
);
const CreateInformasiPages = lazy(() =>
  import("pages/village/website/informasi/create")
);
const UpdateInformasiPages = lazy(() =>
  import("pages/village/website/informasi/update")
);
const ListKategoriInformasiPage = lazy(() =>
  import("pages/village/website/kategori_informasi/lists")
);
const EditKategoriInformasiPage = lazy(() =>
  import("pages/village/website/kategori_informasi/edit")
);
const CreateKategoriInformasiPage = lazy(() =>
  import("pages/village/website/kategori_informasi/create")
);

// POTENSI

const ListsPotensiPages = lazy(() =>
  import("pages/village/website/potensi/lists")
);
const CreatePotensiPages = lazy(() =>
  import("pages/village/website/potensi/create")
);
const DetailPotensiPages = lazy(() =>
  import("pages/village/website/potensi/detail")
);
const UpdatePotensiPages = lazy(() =>
  import("pages/village/website/potensi/update")
);

/**
 *
 * PROFIL
 *
 */

const ProfilDesaPages = lazy(() => import("pages/village/profil/profil-desa"));
const VisiMisiDesaPages = lazy(() => import("pages/village/profil/visi-misi"));
const SejarahDesaPages = lazy(() => import("pages/village/profil/sejarah"));
const BatasWilayahDesaPages = lazy(() =>
  import("pages/village/profil/batas-wilayah")
);

/**
 * HIERARKI
 */

// SUBDESA

const ListsSubDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/subdesa/lists")
);
const CreateSubDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/subdesa/create")
);
const UpdateSubDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/subdesa/update")
);

// SUBSUBDESA

const ListsSubSubDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/subsubdesa/lists")
);
const CreateSubSubDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/subsubdesa/create")
);
const UpdateSubSubDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/subsubdesa/update")
);

// RW

const ListsRWDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/rw/lists")
);
const CreateRWDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/rw/create")
);
const UpdateRWDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/rw/update")
);

// RT

const ListsRTDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/rt/lists")
);
const CreateRTDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/rt/create")
);
const UpdateRTDesaPages = lazy(() =>
  import("pages/village/profil/hierarki-desa/rt/update")
);

/**
 * PERANGKAT
 */

const ListPerangkatPages = lazy(() => import("pages/village/perangkat/lists"));
const CreatePerangkatPages = lazy(() =>
  import("pages/village/perangkat/create")
);
const CopyPerangkatToPenggunaPages = lazy(() =>
  import("pages/village/perangkat/copy-to-user")
);
const EditPerangkatPages = lazy(() => import("pages/village/perangkat/edit"));
const DetailPerangkatPages = lazy(() =>
  import("pages/village/perangkat/detail")
);

/**
 * JABATAN
 */

const ListPositionPages = lazy(() =>
  import("pages/village/perangkat/position/lists")
);
const CreatePositionPages = lazy(() =>
  import("pages/village/perangkat/position/create")
);
const UpdatePositionPages = lazy(() =>
  import("pages/village/perangkat/position/update")
);

/** CITY ROUTES */

/**
 * DASHBOARD
 */

const DashboardCityPages = lazy(() => import("pages/city/dashboard"));

/**
 * CONFIG SURAT
 */

const ListConfigurasiSurat = lazy(() =>
  import("pages/city/letter-config/lists")
);
const CreateConfigurasiSurat = lazy(() =>
  import("pages/city/letter-config/create")
);
const UpdateConfigurasiSurat = lazy(() =>
  import("pages/city/letter-config/update")
);

/**
 * KEWILAYAHAN
 */

// VILLAGE

const ListsVillagePages = lazy(() =>
  import("pages/city/territory/village/lists")
);

const CreateVillagePages = lazy(() =>
  import("pages/city/territory/village/create")
);

const UpdateVillagePages = lazy(() =>
  import("pages/city/territory/village/update")
);

const SettingsHierarkiVillagePages = lazy(() =>
  import("pages/city/territory/village/hierarki")
);

// DOMAIN

const ListsDomainVillagePages = lazy(() =>
  import("pages/city/territory/domain/lists")
);
const CreateDomainVillagePages = lazy(() =>
  import("pages/city/territory/domain/create")
);
const UpdateDomainVillagePages = lazy(() =>
  import("pages/city/territory/domain/update")
);

const ListsPenggunaDesaPage = lazy(() => import("pages/village/users/lists"));
const EditPenggunaDesaPage = lazy(() => import("pages/village/users/update"));
const EditPasswordPenggunaDesaPage = lazy(() =>
  import("pages/village/users/update_password")
);

// KECAMATAN

const ListsKecamatanPages = lazy(() =>
  import("pages/city/territory/kecamatan/lists")
);
const CreateKecamatanPages = lazy(() =>
  import("pages/city/territory/kecamatan/create")
);
const UpdateKecamatanPages = lazy(() =>
  import("pages/city/territory/kecamatan/update")
);

/**
 * ROLE MANAGEMENT
 */

const ListsRoleManagement = lazy(() =>
  import("pages/city/role-management/lists")
);
const CreateRoleManagement = lazy(() =>
  import("pages/city/role-management/create")
);
const UpdateRoleManagement = lazy(() =>
  import("pages/city/role-management/update")
);

/**
 * USERS
 */

const ListsUsersManagement = lazy(() => import("pages/city/users/lists"));
const CreateUsersManagement = lazy(() => import("pages/city/users/create"));
const UpdateUsersManagement = lazy(() => import("pages/city/users/update"));

export const routes = [
  {
    exact: true,
    path: "/",
    access: "all",
    element: <Navigate to="/dashboard" />,
    level: [USER_LEVEL.VILLAGE, USER_LEVEL.CITY, USER_LEVEL.SUPERADMIN],
  },
  {
    exact: true,
    path: "/masuk",
    access: "all",
    element: <LoginPages />,
    level: [USER_LEVEL.ALL],
  },
  {
    exact: true,
    path: "/keluar",
    access: "all",
    level: [USER_LEVEL.VILLAGE, USER_LEVEL.CITY, USER_LEVEL.SUPERADMIN],
    element: (
      <PrivateRoute>
        <LogoutPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/pengaturan/ubah-password",
    access: "all",
    level: [USER_LEVEL.VILLAGE, USER_LEVEL.CITY, USER_LEVEL.SUPERADMIN],
    element: (
      <PrivateRoute>
        <ChangePasswordPages />
      </PrivateRoute>
    ),
  },
  /**
   * SUPERADMIN ROUTES
   */
  {
    name: "Dashboard",
    exact: true,
    access: "all",
    level: [USER_LEVEL.SUPERADMIN],
    path: "/dashboard",
    icon: "bi bi-house",
    element: <PrivateRoute>dashboard superadmin</PrivateRoute>,
  },
  {
    name: "Peta Dasar",
    exact: true,
    access: "all",
    path: "/peta-dasar",
    icon: "bi bi-people",
    level: [USER_LEVEL.SUPERADMIN],
    element: (
      <PrivateRoute>
        <ListsPetaDasarPages />
      </PrivateRoute>
    ),
  },
  /**
   * VILLAGE ROUTES
   */
  {
    name: "Dashboard",
    exact: true,
    access: "dashboard",
    level: [USER_LEVEL.VILLAGE],
    path: "/dashboard",
    icon: "bi bi-house",
    element: (
      <PrivateRoute>
        <DashboardPages />
      </PrivateRoute>
    ),
  },
  {
    name: "Kependudukan",
    exact: true,
    access: "kependudukan",
    icon: "bi bi-people",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Keluarga",
        exact: true,
        path: "/kependudukan/daftar-keluarga",
        element: (
          <PrivateRoute>
            <ListKeluarga />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kependudukan/tambah-keluarga",
        element: (
          <PrivateRoute>
            <CreateKeluarga />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kependudukan/detil-keluarga/:id",
        element: (
          <PrivateRoute>
            <DetailKeluarga />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kependudukan/ubah-keluarga/:id",
        element: (
          <PrivateRoute>
            <EditKeluarga />
          </PrivateRoute>
        ),
      },
      {
        name: "Penduduk",
        exact: true,
        path: "/kependudukan/penduduk",
        element: (
          <PrivateRoute>
            <ListPenduduk />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kependudukan/penduduk/tambah-penduduk",
        element: (
          <PrivateRoute>
            <CreatePenduduk />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kependudukan/penduduk/ubah/:id",
        element: (
          <PrivateRoute>
            <EditPenduduk />
          </PrivateRoute>
        ),
      },
      {
        path: "/kependudukan/penduduk/detail/:id",
        element: (
          <PrivateRoute>
            <DetailPenduduk />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    name: "Peristiwa",
    exact: true,
    access: "peristiwa",
    icon: "bi bi-clipboard-data",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Kelahiran",
        exact: true,
        path: "/peristiwa/kelahiran",
        element: (
          <PrivateRoute>
            <ListsKelahiranPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/kelahiran/tambah-kelahiran",
        element: (
          <PrivateRoute>
            <CreateKelahiranPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/kelahiran/detail/:id",
        element: (
          <PrivateRoute>
            <DetailKelahiranPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/kelahiran/ubah/:id",
        element: (
          <PrivateRoute>
            <UpdateKelahiranPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/kelahiran/tambah-ke-penduduk/:id",
        element: (
          <PrivateRoute>
            <MoveToPendudukKelahiranPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Kematian",
        exact: true,
        path: "/peristiwa/kematian",
        element: (
          <PrivateRoute>
            <ListsKematianPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/kematian/tambah-kematian",
        element: (
          <PrivateRoute>
            <CreateKematianPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/kematian/detail/:id",
        element: (
          <PrivateRoute>
            <DetailKematianPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Lahir Mati",
        exact: true,
        path: "/peristiwa/lahirmati",
        element: (
          <PrivateRoute>
            <ListsLahirMatiPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/lahirmati/tambah-lahirmati",
        element: (
          <PrivateRoute>
            <CreateLahirMatiPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/lahirmati/detail/:id",
        element: (
          <PrivateRoute>
            <DetailLahirMatiPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Pecah KK",
        exact: true,
        path: "/peristiwa/pecahkk",
        element: (
          <PrivateRoute>
            <ListsPecahKKPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/pecahkk/tambah-pecahkk",
        element: (
          <PrivateRoute>
            <CreatePecahKKPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/pecahkk/detail/:id",
        element: (
          <PrivateRoute>
            <DetailPecahKKPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Pindah Keluar",
        exact: true,
        path: "/peristiwa/pindahkeluar",
        element: (
          <PrivateRoute>
            <ListsPindahKeluarPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/pindahkeluar/tambah-pindahkeluar",
        element: (
          <PrivateRoute>
            <CreatePindahKeluarPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/pindahkeluar/detail/:id",
        element: (
          <PrivateRoute>
            <DetailPindahKeluarPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Datang Masuk",
        exact: true,
        path: "/peristiwa/datangmasuk",
        element: (
          <PrivateRoute>
            <ListsDatangMasukPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/datangmasuk/tambah-datangmasuk",
        element: (
          <PrivateRoute>
            <CreateDatangMasukPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/peristiwa/datangmasuk/detail/:id",
        element: (
          <PrivateRoute>
            <DetailDatangMasukPages />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    name: "Layanan",
    exact: true,
    access: "layanan",
    icon: "bi bi-clipboard-pulse",
    level: [USER_LEVEL.VILLAGE],
    path: "/layanan",
    element: (
      <PrivateRoute>
        <ListLayananPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "layanan",
    level: [USER_LEVEL.VILLAGE],
    path: "/layanan/:id/surat",
    element: (
      <PrivateRoute>
        <ListLayananSlugPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "layanan",
    level: [USER_LEVEL.VILLAGE],
    path: "/layanan/:slug/print/:id",
    element: (
      <PrivateRoute>
        <PrintLetterLayananPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "layanan",
    level: [USER_LEVEL.VILLAGE],
    path: "/layanan/:id/tambah-surat",
    element: (
      <PrivateRoute>
        <CreateLayananSlugPages />
      </PrivateRoute>
    ),
  },
  {
    name: "Admininstrasi",
    exact: true,
    access: "administrasi",
    icon: "bi bi-envelope-open",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Surat Masuk",
        exact: true,
        path: "/layanan/surat-masuk",
        element: <PrivateRoute></PrivateRoute>,
      },
      {
        name: "Surat Keluar",
        exact: true,
        path: "/layanan/surat-keluar",
        element: <PrivateRoute></PrivateRoute>,
      },
      {
        name: "Sarana Prasarana",
        exact: true,
        path: "/layanan/sarana-prasarana",
        element: <PrivateRoute></PrivateRoute>,
      },
      {
        name: "Inventaris",
        exact: true,
        path: "/layanan/inventaris",
        element: <PrivateRoute></PrivateRoute>,
      },
    ],
  },
  {
    name: "Website",
    exact: true,
    access: "website",
    icon: "bi bi-globe",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Berita",
        exact: true,
        path: "/website/berita",
        element: (
          <PrivateRoute>
            <ListsBeritaPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/berita/tambah-berita",
        element: (
          <PrivateRoute>
            <CreateBeritaPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/berita/ubah/:slug",
        element: (
          <PrivateRoute>
            <UpdateBeritaPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Hero",
        exact: true,
        path: "/website/hero",
        element: (
          <PrivateRoute>
            <ListsHeroPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/hero/tambah-hero",
        element: (
          <PrivateRoute>
            <CreateHeroPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/hero/ubah/:slug",
        element: (
          <PrivateRoute>
            <UpdateHeroPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Informasi",
        exact: true,
        path: "/website/informasi",
        element: (
          <PrivateRoute>
            <ListsInformasiPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/informasi/tambah-informasi",
        element: (
          <PrivateRoute>
            <CreateInformasiPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/informasi/ubah/:slug",
        element: (
          <PrivateRoute>
            <UpdateInformasiPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Potensi",
        exact: true,
        path: "/website/potensi",
        element: (
          <PrivateRoute>
            <ListsPotensiPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/potensi/tambah-potensi",
        element: (
          <PrivateRoute>
            <CreatePotensiPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/potensi/detail/:slug",
        element: (
          <PrivateRoute>
            <DetailPotensiPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/website/potensi/ubah/:slug",
        element: (
          <PrivateRoute>
            <UpdatePotensiPages />
          </PrivateRoute>
        ),
      },
      // {
      //   name: "Survey",
      //   exact: true,
      //   path: "/layanan/survey",
      //   element: <PrivateRoute></PrivateRoute>,
      // },
      {
        path: "/website/kategori-berita/daftar-kategori-berita",
        element: (
          <PrivateRoute>
            <ListsKategoriBeritaPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/website/kategori-berita/tambah-kategori-berita",
        element: <PrivateRoute component={<CreateKategoriBeritaPage />} />,
      },
      {
        path: "/website/kategori-berita/ubah-kategori-berita/:id",
        element: (
          <PrivateRoute>
            <EditKategoriBeritaPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/website/kategori-informasi/daftar-kategori-informasi",
        element: (
          <PrivateRoute>
            <ListKategoriInformasiPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/website/kategori-informasi/tambah-kategori-informasi",
        element: (
          <PrivateRoute>
            <CreateKategoriInformasiPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/website/kategori-informasi/ubah-kategori-informasi/:id",
        element: (
          <PrivateRoute>
            <EditKategoriInformasiPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    name: "Profil",
    exact: true,
    access: "profil",
    icon: "bi bi-person",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Profil Desa",
        exact: true,
        path: "/profil/profil-desa",
        element: (
          <PrivateRoute>
            <ProfilDesaPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Visi Misi",
        exact: true,
        path: "/profil/visi-misi",
        element: (
          <PrivateRoute>
            <VisiMisiDesaPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Sejarah",
        exact: true,
        path: "/profil/sejarah",
        element: (
          <PrivateRoute>
            <SejarahDesaPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Batas Desa",
        exact: true,
        path: "/profil/batas-desa",
        element: (
          <PrivateRoute>
            <BatasWilayahDesaPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Hierarki Desa",
        exact: true,
        path: "/profil/hierarki-desa",
        children: [
          {
            key: "subdesa",
            name: "Subdesa",
            exact: true,
            path: "/profil/hierarki-desa/subdesa",
            element: (
              <PrivateRoute>
                <ListsSubDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "subdesa",
            path: "/profil/hierarki-desa/subdesa/tambah-subdesa",
            element: (
              <PrivateRoute>
                <CreateSubDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "subdesa",
            path: "/profil/hierarki-desa/subdesa/ubah/:id",
            element: (
              <PrivateRoute>
                <UpdateSubDesaPages />
              </PrivateRoute>
            ),
          },
          {
            name: "Subsubdesa",
            exact: true,
            key: "subsubdesa",
            path: "/profil/hierarki-desa/subsubdesa",
            element: (
              <PrivateRoute>
                <ListsSubSubDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "subsubdesa",
            path: "/profil/hierarki-desa/subsubdesa/tambah-subsubdesa",
            element: (
              <PrivateRoute>
                <CreateSubSubDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "subsubdesa",
            path: "/profil/hierarki-desa/subsubdesa/ubah/:id",
            element: (
              <PrivateRoute>
                <UpdateSubSubDesaPages />
              </PrivateRoute>
            ),
          },
          {
            name: "RW",
            exact: true,
            key: "rw",
            path: "/profil/hierarki-desa/rw",
            element: (
              <PrivateRoute>
                <ListsRWDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "rw",
            path: "/profil/hierarki-desa/rw/tambah-rw",
            element: (
              <PrivateRoute>
                <CreateRWDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "rw",
            path: "/profil/hierarki-desa/rw/ubah/:id",
            element: (
              <PrivateRoute>
                <UpdateRWDesaPages />
              </PrivateRoute>
            ),
          },
          {
            name: "RT",
            key: "rt",
            exact: true,
            path: "/profil/hierarki-desa/rt",
            element: (
              <PrivateRoute>
                <ListsRTDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "rt",
            path: "/profil/hierarki-desa/rt/tambah-rt",
            element: (
              <PrivateRoute>
                <CreateRTDesaPages />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            key: "rt",
            path: "/profil/hierarki-desa/rt/ubah/:id",
            element: (
              <PrivateRoute>
                <UpdateRTDesaPages />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "PPID",
    exact: true,
    access: "ppid",
    path: "/ppid",
    icon: "bi bi-pie-chart",
    level: [USER_LEVEL.VILLAGE],
    element: <PrivateRoute></PrivateRoute>,
  },
  {
    name: "Laporan Warga",
    exact: true,
    access: "laporanwarga",
    icon: "bi bi-flag",
    level: [USER_LEVEL.VILLAGE],
  },
  {
    name: "Perangkat",
    access: "perangkat",
    icon: "bi bi-person-fill",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Data Perangkat",
        exact: true,
        path: "/perangkat/daftar-perangkat",
        element: (
          <PrivateRoute>
            <ListPerangkatPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/perangkat/tambah-perangkat",
        element: (
          <PrivateRoute>
            <CreatePerangkatPages />
          </PrivateRoute>
        ),
      },
      {
        path: "/perangkat/ubah-perangkat/:id",
        element: (
          <PrivateRoute>
            <EditPerangkatPages />
          </PrivateRoute>
        ),
      },
      {
        path: "/perangkat/detil-perangkat/:id",
        element: (
          <PrivateRoute>
            <DetailPerangkatPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/perangkat/tambah-ke-pengguna/:id",
        element: (
          <PrivateRoute>
            <CopyPerangkatToPenggunaPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Jabatan",
        exact: true,
        path: "/perangkat/daftar-jabatan",
        element: (
          <PrivateRoute>
            <ListPositionPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/perangkat/tambah-jabatan",
        element: (
          <PrivateRoute>
            <CreatePositionPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/perangkat/ubah-jabatan/:id",
        element: (
          <PrivateRoute>
            <UpdatePositionPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Presensi",
        exact: true,
        path: "/perangkat/presensi",
        element: <PrivateRoute></PrivateRoute>,
      },
    ],
  },
  {
    name: "Keuangan",
    exact: true,
    access: "keuangan",
    icon: "bi bi-wallet",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Pendapatan Desa",
        exact: true,
        path: "/keuangan/pendapatan-desa",
        element: <PrivateRoute></PrivateRoute>,
      },
      {
        name: "Anggaran Desa",
        exact: true,
        path: "/keuangan/anggaran-desa",
        element: <PrivateRoute></PrivateRoute>,
      },
    ],
  },
  {
    name: "CCTV",
    exact: true,
    access: "cctv",
    icon: "bi bi-camera-video",
    level: [USER_LEVEL.VILLAGE],
  },
  {
    name: "Peta",
    exact: true,
    access: "peta",
    icon: "bi bi-geo",
    level: [USER_LEVEL.VILLAGE],
  },

  /** CITY ROUTES */
  {
    name: "Dashboard",
    exact: true,
    access: "all",
    level: [USER_LEVEL.CITY],
    path: "/dashboard",
    icon: "bi bi-house",
    element: (
      <PrivateRoute>
        <DashboardCityPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "konfigurasisurat",
    path: "/konfigurasi-surat",
    name: "Konfigurasi Surat",
    icon: "bi bi-card-text",
    level: [USER_LEVEL.CITY],
    element: (
      <PrivateRoute>
        <ListConfigurasiSurat />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "konfigurasisurat",
    path: "/konfigurasi-surat/tambah-konfigurasi-surat",
    level: [USER_LEVEL.CITY],
    element: (
      <PrivateRoute>
        <CreateConfigurasiSurat />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "konfigurasisurat",
    path: "/konfigurasi-surat/ubah-konfigurasi-surat/:id",
    level: [USER_LEVEL.CITY],
    element: (
      <PrivateRoute>
        <UpdateConfigurasiSurat />
      </PrivateRoute>
    ),
  },
  // {
  //   exact: true,
  //   path: "/tarik-data",
  //   name: "Tarik Data",
  //   access: "tarikdata",
  //   level: [USER_LEVEL.CITY],
  //   icon: "bi bi-file-bar-graph",
  //   element: <PrivateRoute>Tarik Data</PrivateRoute>,
  // },
  {
    name: "Pengguna",
    exact: true,
    icon: "bi bi-person",
    access: "pengguna",
    level: [USER_LEVEL.VILLAGE],
    children: [
      {
        name: "Data Pengguna",
        path: "/pengguna/daftar-pengguna",
        element: (
          <PrivateRoute>
            <ListsPenggunaDesaPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/pengguna/ubah-password/:id",
        element: (
          <PrivateRoute>
            <EditPasswordPenggunaDesaPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/pengguna/ubah-pengguna/:id",
        element: (
          <PrivateRoute>
            <EditPenggunaDesaPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    name: "Pengguna",
    exact: true,
    icon: "bi bi-person",
    access: "pengguna",
    level: [USER_LEVEL.CITY],
    path: "/pengguna/daftar-pengguna",
    element: (
      <PrivateRoute>
        <ListsUsersManagement />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "pengguna",
    level: [USER_LEVEL.CITY],
    path: "/pengguna/tambah-pengguna",
    element: (
      <PrivateRoute>
        <CreateUsersManagement />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "pengguna",
    level: [USER_LEVEL.CITY],
    path: "/pengguna/ubah-pengguna/:id",
    element: (
      <PrivateRoute>
        <UpdateUsersManagement />
      </PrivateRoute>
    ),
  },
  {
    name: "Manajemen Akses",
    exact: true,
    access: "manajemenakses",
    level: [USER_LEVEL.CITY],
    icon: "bi bi-person-bounding-box",
    path: "/managemen-akses/daftar-managemen-akses",
    element: (
      <PrivateRoute>
        <ListsRoleManagement />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "manajemenakses",
    level: [USER_LEVEL.CITY],
    path: "/managemen-akses/tambah-managemen-akses",
    element: (
      <PrivateRoute>
        <CreateRoleManagement />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    access: "manajemenakses",
    level: [USER_LEVEL.CITY],
    path: "/managemen-akses/ubah-managemen-akses/:id",
    element: (
      <PrivateRoute>
        <UpdateRoleManagement />
      </PrivateRoute>
    ),
  },
  {
    name: "Kewilayahan",
    exact: true,
    access: "kewilayahan",
    icon: "bi bi-geo-alt",
    level: [USER_LEVEL.CITY],
    children: [
      {
        name: "Desa",
        exact: true,
        path: "/kewilayahan/daftar-desa",
        element: (
          <PrivateRoute>
            <ListsVillagePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kewilayahan/tambah-desa",
        element: (
          <PrivateRoute>
            <CreateVillagePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kewilayahan/ubah-desa/:id",
        element: (
          <PrivateRoute>
            <UpdateVillagePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kewilayahan/pengaturan-hierarki-desa/:id",
        element: (
          <PrivateRoute>
            <SettingsHierarkiVillagePages />
          </PrivateRoute>
        ),
      },
      {
        name: "Kecamatan",
        exact: true,
        path: "/kewilayahan/daftar-kecamatan",
        element: (
          <PrivateRoute>
            <ListsKecamatanPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kewilayahan/tambah-kecamatan",
        element: (
          <PrivateRoute>
            <CreateKecamatanPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kewilayahan/ubah-kecamatan/:id",
        element: (
          <PrivateRoute>
            <UpdateKecamatanPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Domain Desa",
        path: "/kewilayahan/daftar-domain-desa",
        element: (
          <PrivateRoute>
            <ListsDomainVillagePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kewilayahan/tambah-domain-desa",
        element: (
          <PrivateRoute>
            <CreateDomainVillagePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/kewilayahan/ubah-domain-desa/:id",
        element: (
          <PrivateRoute>
            <UpdateDomainVillagePages />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "*",
    exact: true,
    access: "all",
    level: [USER_LEVEL.ALL],
    element: <Navigate to="/masuk" />,
  },
];
